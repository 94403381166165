import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
// import { setPasswordAction, setSelectedFactorAction, setUsernameAction } from '../state/userdata'
import { useNavigate } from 'react-router-dom'
import { POPUPTYPE, setCurrentPopupAction } from '../state/appdata'
import { GL } from '../services/services'







const mapState = (state: RootState) => ({
    appdata:state.appdata
})


const mapDispatch = {
    setPopup: (popup: POPUPTYPE) => (setCurrentPopupAction(popup))
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps

const Footer = (props: Props) => {

  
    return (
        <div className="footer" dangerouslySetInnerHTML={{__html:props.appdata.footer}} />
    )
}

export default connect(
    mapState,
    mapDispatch
)(Footer)