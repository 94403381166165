import { connect } from 'react-redux'
import { RootState } from '../state/store'
import { setFactorIdAction, setNavigateAction, setPasswordAction, setSelectedMSISDNAction, setUsernameAction } from '../state/userdata'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GL, logFE } from '../services/services'
import { POPUPTYPE, setCurrentPopupAction } from '../state/appdata'
import { setAuthTokenAction, setChallengeIdAction } from '../state/serviceData'
import { isMobile, performLogin } from '../services/common-ui'
import { ErrorBox } from './ErrorBox'
import { tagHoDienticatoCredenziali, tagLoginAccediLink, tagLoginQualeUserNameLink } from '../services/utag'




const mapState = (state: RootState) => ({
    userdata: state.userdata,
    appdata: state.appdata,
    service: state.service
})


const mapDispatch = {
    setUsername: (userid: string) => (setUsernameAction(userid)),
    setPassword: (password: string) => (setPasswordAction(password)),
    setNavigate: (navigate: any) => (setNavigateAction(navigate)),
    // setWaiting: (wait: boolean) => (setWaitingAction(wait)),
    setPopup: (popup: POPUPTYPE) => (setCurrentPopupAction(popup)),
    setAuthToken: (authToken: string) => (setAuthTokenAction(authToken)),
    setChallengeId: (challengeId: string) => (setChallengeIdAction(challengeId)),
    setSelectedMSISDN: (msisdn: string) => (setSelectedMSISDNAction(msisdn)),
    setSelectedFactorId: (factorId: string) => (setFactorIdAction(factorId))
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps

const UserPasswordComponent = (props: Props) => {
    // this is an example of local property used only in this component
    // that we do not want to save in the central store
    const [localflag, setLocalFlag] = useState("")
    const navigate = useNavigate()


    const userInputField = useRef(null);
    const pwdInputField = useRef(null);
    const accediButtonref=useRef(null);

    const [acc1, setAcc1] = useState(true)
    const [acc2, setAcc2] = useState(true)

    const [passwordVisible, setPasswordVisible] = useState(false)

   

    useEffect(()=>{
        try {
            

          /*   setTimeout(()=>{
                logFE('focusing pwd');
                (pwdInputField.current as any).focus()
            },70);
            setTimeout(()=>{
                logFE('focusing user');
                (userInputField.current as any).focus();
            },5);
            setTimeout(()=>{
                logFE('focusing pwd');
                (accediButtonref.current as any).focus();
            },150);
            setTimeout(()=>{
                logFE('focusing pwd');
                (userInputField.current as any).focus()
            },170);
             */
           // (accediButtonref.current as any).focus()
        } catch (error) {
            logFE('error focusing',error);
        }
       
        
    },[])

    

    const isLoginButtonEnabled = () => {
        return props.userdata.username && props.userdata.password
    }

    //logFE('is app:',props.appdata.app)



    const doLogin=()=>{
        if (isLoginButtonEnabled()) {
            tagLoginAccediLink();
            performLogin(navigate)
        }
    }

    const manageEnter=(evt:any)=>{
        //logFE('evt: ',evt)
        if(evt && evt.code && evt.code=='Enter' ){
           doLogin()
        }

    }

    return (
        <div id="tab_login" >
            < div className="mainContent" onKeyDown={manageEnter} >
             


                {
                    props.appdata.loginError ? (
                        <ErrorBox text={props.appdata.loginError} type='ERROR' />

                    ) : (
                        <div></div>
                    )
                }
                <div className="input-field" id="inputUsername">
                    <input ref={userInputField} required  pattern="\S+.*" id='username' value={props.userdata.username} placeholder="" onChange={(e) => {
                        logFE('changed:', e.target.value)
                        props.setUsername(e.target.value.trim())
                    }}></input>
                    <label htmlFor="username">{GL('ED_MFA_USER')}</label>
                    <div className='info-icon' onClick={e =>{ 
                        tagLoginQualeUserNameLink();
                        props.setPopup('USERINFO');
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 16.3125V10.5H11" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11 16.5H13" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.75 7.875C11.8881 7.875 12 7.76307 12 7.625C12 7.48693 11.8881 7.375 11.75 7.375C11.6119 7.375 11.5 7.48693 11.5 7.625C11.5 7.76307 11.6119 7.875 11.75 7.875Z" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.75 7.75C11.819 7.75 11.875 7.69404 11.875 7.625C11.875 7.55596 11.819 7.5 11.75 7.5C11.681 7.5 11.625 7.55596 11.625 7.625C11.625 7.69404 11.681 7.75 11.75 7.75Z" stroke="#111111" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className="input-field" id="inputPassword">
                    <input ref={pwdInputField}  required pattern="\S+.*" id='password' type={passwordVisible ? 'text' : 'password'} 
                    value={props.userdata.password} placeholder="" 
                    onChange={(e) => { props.setPassword(e.target.value.trim()) }}></input>
                    <label htmlFor="password">{GL('ED_MFA_PASSWORD')}</label>
                    {
                        (!passwordVisible) ? (
                            <div className='info-icon' onClick={e => setPasswordVisible(!passwordVisible)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 4.03125L3.99999 20.0312M12 18.5C5.56374 18.5 2.65374 12.5 2.54249 12.2238C2.48753 12.0616 2.48753 11.8859 2.54249 11.7238C2.65374 11.5 5.56374 5.5 12 5.5C18.4362 5.5 21.3462 11.5 21.4575 11.7762C21.5124 11.9384 21.5124 12.1141 21.4575 12.2762C21.3462 12.5 18.4362 18.5 12 18.5ZM12 15.5C11.077 15.4961 10.1933 15.1261 9.5428 14.4714C8.89226 13.8166 8.52803 12.9305 8.53001 12.0076C8.532 11.0846 8.90004 10.2001 9.55339 9.54814C10.2067 8.89619 11.092 8.53005 12.015 8.53005C12.938 8.53005 13.8233 8.89619 14.4766 9.54814C15.13 10.2001 15.498 11.0846 15.5 12.0076C15.502 12.9305 15.1377 13.8166 14.4872 14.4714C13.8367 15.1261 12.953 15.4961 12.03 15.5H12Z" stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </div>
                        ) : (<div className='info-icon' onClick={e => setPasswordVisible(!passwordVisible)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 18.5C5.56375 18.5 2.65375 12.5 2.5425 12.2238C2.48754 12.0616 2.48754 11.8859 2.5425 11.7238C2.65375 11.5 5.56375 5.5 12 5.5C18.4363 5.5 21.3463 11.5 21.4575 11.7762C21.5125 11.9384 21.5125 12.1141 21.4575 12.2762C21.3463 12.5 18.4363 18.5 12 18.5Z" stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 15.5C11.077 15.4961 10.1933 15.1261 9.54281 14.4714C8.89228 13.8166 8.52804 12.9305 8.53003 12.0076C8.53202 11.0846 8.90006 10.2001 9.55341 9.54814C10.2068 8.89619 11.092 8.53005 12.015 8.53005C12.938 8.53005 13.8233 8.89619 14.4766 9.54814C15.13 10.2001 15.498 11.0846 15.5 12.0076C15.502 12.9305 15.1378 13.8166 14.4872 14.4714C13.8367 15.1261 12.953 15.4961 12.03 15.5H12Z" stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>)
                    }


                </div>
                {/* <div className='loginError' dangerouslySetInnerHTML={{ __html: props.appdata.loginError }}></div> */}
                <p className='listLink'><a href={isMobile()?GL('ED_MFA_CREDENTIALS_LINK_APP'):GL('ED_MFA_CREDENTIALS_LINK')} onClick={(e)=>{
                    tagHoDienticatoCredenziali();
                    
                }}>{GL('ED_MFA_CREDENTIALS')}</a></p>
                {/* <div className="checkbox"><input type="checkbox" id='rememberme' />
                    <label htmlFor="rememberme"> {GL('ED_MFA_REMEMBER_ME')}</label>
                </div> */}
                <div className={'actionsButton' + (isLoginButtonEnabled() ? '' : ' disabled')}>
                    <button ref={accediButtonref} onClick={e => {
                       doLogin()

                    }} type="submit">Accedi</button>
                </div>
               
            </div >


            <div className="secondaryContent">
                <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HELP_NEEDED') }}></h3>
                <div className="accordion">
                    <ul>
                        {(!props.appdata.app)?(<li id="accordionPrivato" onClick={e => {
                                setAcc1(!acc1)
                            }}>
                            <span dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HELP_CONSUMER') }}></span>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id="arrowPrivato" className={acc1 ? "arrow arrowDown" : "arrow arrowUp"} 
                            >
                                <g id="Navigation &#38; Actions/chevron-down">
                                    <path id="Vector" d="M3.5 16.75L12 8.25L20.5 16.75" stroke="#E60000" strokeMiterlimit="10" strokeLinecap="round" />
                                </g>
                            </svg>
                            {/* dangerouslySetInnerHTML={{ __html: `<div>${GL('ED_MFA_ACC_CONSUMER')}</div>` }} */}
                            <div className="accordionContent" id="contentPrivato" dangerouslySetInnerHTML={{ __html: GL('ED_MFA_ACC_CONSUMER') }}></div>

                        </li>):(<div></div>)}
                        
                        <li id="accordionBusiness" onClick={e => {
                                setAcc2(!acc2)
                            }}>
                            <span dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HELP_BUSINESS') }}></span>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id="arrowBusiness" 
                            className={acc2 ? ("arrow arrowDown") : ("arrow arrowUp")} >
                                <g id="Navigation &#38; Actions/chevron-down">
                                    <path id="Vector" d="M3.5 16.75L12 8.25L20.5 16.75" stroke="#E60000" strokeMiterlimit="10" strokeLinecap="round" />
                                </g>
                            </svg>

                            <div className="accordionContent" id="contentBusiness" dangerouslySetInnerHTML={{ __html: GL('ED_MFA_ACC_BUSINESS') }}></div>
                        </li>
                    </ul>
                </div>
            </div>


        </div >
    )
}

// Typical usage: `connect` is called after the component is defined
export default connect(
    mapState,
    mapDispatch
)(UserPasswordComponent)