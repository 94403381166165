import { Action } from "./action";



export type POPUPTYPE = 'NONE' | 'VERIFICA_IDENTITA' | 'INSERISCI_NUOVO_NUMERO' | 'INSERISCI_NUMERO_MANUALMENTE' |  'INSERISCI_EMAIL_MANUALMENTE' |'USERINFO' | 'ACCESSO_NON_DISPONIBILE' | 'TI_AIUTIAMO_NOI'|'NUMERO_NON_VALIDO'|'ENROLLMENT_ERROR';
export type LOGIN_STATUS = 'LOGGED' | 'UNLOGGED' | 'REQUESTING_OTP';
export type APP_PATH = 'MFA_LOGIN' | 'NO_MFA_LOGIN' | 'MFA_ENROLL';



const InitialState = {
    waiting: 0,
    // isVerifyIdentity: false,
    // isSelectNewNumber: false,
    // isInsertNumberManually: false,
    currentPopup: 'NONE',
    appPath: 'NO_MFA_LOGIN',
    redirectURL: '',
    loginError: '',
    app: false,
    maskingMSISDN: true,
    footer:""
}





const SET_WAITING_ACTION_TYPE: string = 'SET_WAITING'
// const SET_VERIFY_IDENTITY_ACTION_TYPE: string = 'SET_VERIFY_IDENTITY'
// const SET_SELECT_NEW_NUMBER_ACTION_TYPE: string = 'SET_NEW_NUMBER'
// const SET_INSERT_NUMBER_MANUALLY_ACTION_TYPE: string = 'SET_INSERT_NUMBER_MANUALLY'
const SET_CURRENT_POPUP_ACTION_TYPE: string = 'SET_CURRENT_POPUP'

const SET_APP_PATH_ACTION_TYPE: string = 'SET_APP_PATH'
const SET_REDIRECT_URL_ACTION_TYPE: string = 'SET_REDIRECT_URL'
const SET_LOGIN_ERROR_ACTION_TYPE: string = 'SET_LOGIN_ERROR'
const SET_APP_ACTION_TYPE: string = 'SET_APP'
const SET_FOOTER_ACTION_TYPE: string = 'SET_FOOTER'

export const setWaitingAction = (waiting: boolean): Action => {
    return ({
        type: SET_WAITING_ACTION_TYPE,
        payload: waiting
    })
}
// export const setVerifyIdentityAction = (status: boolean): Action => {
//     return ({
//         type: SET_VERIFY_IDENTITY_ACTION_TYPE,
//         payload: status
//     })
// }
// export const setSelectNewNumberAction = (status: boolean): Action => {
//     return ({
//         type: SET_SELECT_NEW_NUMBER_ACTION_TYPE,
//         payload: status
//     })
// }
// export const setInsertNumberManuallyAction = (status: boolean): Action => {
//     return ({
//         type: SET_INSERT_NUMBER_MANUALLY_ACTION_TYPE,
//         payload: status
//     })
// }

export const setCurrentPopupAction = (popup: POPUPTYPE): Action => {
    return ({
        type: SET_CURRENT_POPUP_ACTION_TYPE,
        payload: popup
    })
}


export const setAppPathAction = (path: APP_PATH): Action => {
    return ({
        type: SET_APP_PATH_ACTION_TYPE,
        payload: path
    })
}

export const setRedirectURL = (url: string): Action => {
    return ({
        type: SET_REDIRECT_URL_ACTION_TYPE,
        payload: url
    })
}

export const setLoginError = (msg: string): Action => {
    
    return ({
        type: SET_LOGIN_ERROR_ACTION_TYPE,
        payload: msg
    })
}
export const setApp = (isApp: boolean): Action => {
    return ({
        type: SET_APP_ACTION_TYPE,
        payload: isApp
    })
}


export const setFooter = (footer: string): Action => {
    return ({
        type: SET_FOOTER_ACTION_TYPE,
        payload: footer
    })
}


export function appdataReducer(state = InitialState, action: any) {
    switch (action.type) {
        case SET_WAITING_ACTION_TYPE:

            return { ...state, waiting: action.payload ? state.waiting + 1 : ((state.waiting > 0) ? state.waiting - 1 : 0) }
        case SET_CURRENT_POPUP_ACTION_TYPE:
            return { ...state, currentPopup: action.payload }
        case SET_APP_PATH_ACTION_TYPE:
            return { ...state, appPath: action.payload }
        case SET_REDIRECT_URL_ACTION_TYPE:
            return { ...state, redirectURL: action.payload }
        case SET_LOGIN_ERROR_ACTION_TYPE:
            return { ...state, loginError: action.payload }
        case SET_APP_ACTION_TYPE:
            return { ...state, app: action.payload }
        case SET_FOOTER_ACTION_TYPE:
                return { ...state, footer: action.payload }
        default:
            return state
    }
}