import { store } from "../state/store"; 
import { getSelectedFactor, isMobile, isSelectedFactorEmail } from "./common-ui";
import { logFE } from "./services";

let utag: any = (window as any).utag
logFE('utag found', utag)
let w:any=window

export const getUtag = () => {
    return (window as any).utag
}

const getMaketLower=()=>{
    let market:string=store.getState().userdata.market||'CONSUMER'
    return market.toLowerCase()
}

const getMaketCamel=()=>{
    let market:string=store.getState().userdata.market||'CONSUMER'
    return (market==='CONSUMER')?'Consumer':'Business';
}



const getEnvironment = () => {
    return isMobile() ? "App Business" : "Web";
}

export const tagPage = (utag_data: any) => {
    let utag = getUtag()
    if (!utag) {
        logFE('************************************  utag is empty *******************************')
        return
    }
    try {
        logFE('performing utag view', utag_data)
        utag.view(utag_data);
    } catch (e) {
        logFE('errorr sendong utag', e)
        try {
            utag.DB("ERROR IN SENDING PAGE VIEW: " + e)
        } catch (error) {
            logFE('error in utag DB',error);
        }
        
    }

}


export const tagLink = (utag_data: any, key: string, value: string) => {
    try {
        let data={
            ...utag_data,
            key: value,
            "link_name": utag_data.page_name + ":test",
        }
        logFE("tagging:",data);
        utag.link(data)
    } catch (e) {
        logFE('error sending utag link data', e)
        try {
            utag.DB('CUSTOM LINK ERROR: ' + e);
        } catch (error) {
            logFE('error in utag DB',error);
        }
        
    }

}

const getLoginPageUtagData = () => {

    w.utag_data = isMobile() ? {
        page_name: isMobile() ? "MyVodafoneBusiness:accedi" : "accedi",
        page_channel: "Business",
        page_section: "Landing",
        page_subsection: "login",
        page_environment: getEnvironment(),
        journey_name: "login",
        journey_type: "journey",
        event_name: "view",
        visitor_login_status: "logged out"
    } : {
        page_name: "VFIT:accedi",
        page_channel: "consumer",
        page_section: "Self Service",
        page_subsection: "login",
        page_environment: "Web",
        journey_name: "login",
        journey_type: "journey",
        event_name: "view",
        visitor_login_status: "logged out"
    }
    return w.utag_data;
}



export const tagLoginPage = () => {
    setTimeout(() => {
        tagPage(getLoginPageUtagData())
    }, 1260);


}





export const tagClick = (utag_data: any, linkName: string) => {
    let utag = getUtag();
    if (!utag) {
        logFE('utag is empty')
        return
    }

    let link_data = {
        ...utag_data,
        event_name: "ui_interaction",
        event_category: "users",
        event_action: "click",
        link_name: utag_data.page_name + linkName
    }
    logFE('sending utag link :' + linkName)

    try {
        utag.link(link_data)
    } catch (e) {
        logFE('error sending utag link data', e)
       
        try {
            utag.DB('CUSTOM LINK ERROR: ' + e);
        } catch (error) {
            logFE('error in utag DB',error);
        }
    }
}



export const tagHoDienticatoCredenziali = () => {
    try {
        tagClick(getLoginPageUtagData(), ":recupera credenziali")
    } catch (error) {
        logFE('error in tagging: ',error)
    }
    
}

export const tagLoginAccediLink = () => {
    try {
        tagClick(getLoginPageUtagData(), ":accedi")
    } catch (error) {
        logFE('error in tagging: ',error)
    }
    
}


export const tagLoginQualeUserNameLink = () => {
    try {
        tagClick(getLoginPageUtagData(), ":info username");
    } catch (error) {
        logFE('error in tagging: ',error)
    }
    
}

export const tagLoginPageError = (errorMessage: string) => {
    try {
        let utag_data = getLoginPageUtagData();
        tagErrorGeneric(utag_data, errorMessage, ":Alert:login error");
    } catch (error) {
        logFE('error in tagging: ',error)
    }
   
}

export const tagLoginPageErrorAccountBloccato = (errorMessage: string) => {
    

    try {
        let utag_data = getLoginPageUtagData();
        tagErrorGeneric(utag_data, errorMessage, ":Alert:login errorr-account bloccato");
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}

export const tagErrorGeneric = (utag_data: any, errorMessage: string, category: string) => {
   
    try { 
        let utag = getUtag();
        let link_data = {
            ...utag_data,
            event_name: "form_error",
            event_category: "error",
            form_error: errorMessage,
            link_name: utag_data.page_name + category
        };
    
        logFE('tag login error:', link_data)
        utag.link(link_data);
    } catch (e: any) {
        logFE('error tagging login error',e)
    }
   
}

const getregistrationPageData = () => {
    w.utag_data = (isMobile() ?
        {
            page_name: isMobile() ? "MyVodafoneBusiness:registrati" : "registrati",
            page_channel: "Business",
            page_section: "Landing",
            page_subsection: "registrazione",
            page_environment: getEnvironment(),
            journey_name: "registrazione",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:registrati", //<accedi or registrati>
            page_channel: "consumer",
            page_section: "Self Service",
            page_subsection: "registrazione",
            page_environment: "Web",
            journey_name: "registrazione",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

export const tagRegistrationPage = () => {

    try {
        return tagPage(getregistrationPageData())
    } catch (error) {
        logFE('error in tagging: ',error)
    }

    
}



const getEnrollmentStartData = () => {
    w.utag_data= (
        isMobile() ? ({
            page_name: "MyVodafoneBusiness:accedi:configure 2fa",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }) : ({
            page_name: "VFIT:"+getMaketLower()+":accedi:configure 2fa",
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        })
    )
    return w.utag_data
}


export const tagEnrollmentStartPage = () => {
    
    try {
        tagPage(getEnrollmentStartData())
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}

export const toEseguiClick = () => {
    

    try {
        tagClick(getEnrollmentStartData(), ":procedi");
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}



const getEnrollSelectNumber = () => {
    w.utag_data= (
        isMobile() ? ({
            page_name: "MyVodafoneBusiness:accedi:configure 2fa:contacts",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }) : ({
            page_name: "VFIT:"+getMaketLower()+":accedi:configure 2fa:contacts",// or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"

        })
    )
    return w.utag_data
}


export const tagEnrollSelectNumberPage = () => {

    try {
        tagPage(getEnrollSelectNumber())
    } catch (error) {
        logFE('error in tagging: ',error)
    }

    
}

export const tagEnterNumberManuallyClick = () => {
    

    try {
        tagClick(getEnrollSelectNumber(), ":enter number")
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}

export const tagEnterEmailManuallyClick = () => {
    

    try {
        tagClick(getEnrollSelectNumber(), ":enter email")
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}

export const tagEnrollSelectNumberInviaCodiceClick = () => {
    

    try {
        tagClick(getEnrollSelectNumber(), ":invia codice");
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}



const getInserisciNumeroManualeTagData = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:configure 2fa:contacts:enter number",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:configure 2fa:contacts:enter number", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

export const tagInserisciNumeroManualmentePageTag = () => {
    
    try {
        tagPage(getInserisciNumeroManualeTagData());
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}




const getInserisciEmailManualeTagData = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:configure 2fa:contacts:enter email",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:configure 2fa:contacts:enter email", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

export const tagInserisciEmailManualmentePageTag = () => {
    
    try {
        tagPage(getInserisciEmailManualeTagData())
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}
export const tagEmailAlreadyUsedError = () => {
   

    try {
        tagErrorGeneric(getInserisciEmailManualeTagData(), "email already used", ":input error");
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}

export const tagInserisceiNumeroManualmenteClick = () => {
    
    try {
        tagClick(getInserisciNumeroManualeTagData(), ":invia codice");
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}

const getEnrollmentEndData = () => {
    let mode=isSelectedFactorEmail()?"email":"number"
    w.utag_data= (
        isMobile() ? {
            page_name: `MyVodafoneBusiness:accedi:configure 2fa:${mode}:typ`,
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+`:accedi:configure 2fa:${mode}:typ`, // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }

    )
    return w.utag_data
}


export const tagEnrollEndPage = () => {
    
    try {
        tagPage(getEnrollmentEndData());
    } catch (error) {
        logFE('error in tagging: ',error)
    }
}

const getVerificaIdentitaTagDataNumber = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:otp-number",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:otp-number", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

const getVerificaIdentitaTagDataEmail = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:otp-mail",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:otp-mail", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}


const getVerificaIdentitaTagData=()=>{
   return  isSelectedFactorEmail()?getVerificaIdentitaTagDataEmail():getVerificaIdentitaTagDataNumber()
}
export const tagVerificaIdentitaPage = () => {
    try {
        tagPage(getVerificaIdentitaTagData());
    } catch (error) {
        logFE('error in tagVerificaIdentitaPage');
    }
    
}

export const tagVerificaIdentitaRichiediNuovoCodiceclick = () => {
    
    try {
        tagClick(getVerificaIdentitaTagData(), ":nuovo codice");
    } catch (error) {
        logFE('error in tagVerificaIdentitaRichiediNuovoCodiceclick');
    }
}

export const tagVerificaIdentitaNonAccedoClick = () => {
    
    try {
        tagClick(getVerificaIdentitaTagData(), ":non accedo");
    } catch (error) {
        logFE('error in tagging',error);
    }
}



export const verificaIdentitaWrongCodeTag = () => {
    
    try {
        tagErrorGeneric(getVerificaIdentitaTagData(), "incorrect code", ":code error");
    } catch (error) {
        logFE('error in tagging',error);
    }
}


const getSelezionaNuovoNumeroTagData = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:otp-multicontact",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:otp-multicontact", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Care",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

export const tagSelezionaNuovoNumeroPage = () => {
    
    try {
        tagPage(getSelezionaNuovoNumeroTagData())
    } catch (error) {
        logFE('error in tagging',error);
    }
}


export const tagSelezionaNuovoNumeroInviaCodiceTag = () => {
    
    try {
        tagClick(getSelezionaNuovoNumeroTagData(), ":invia codice")
    } catch (error) {
        logFE('error in tagging',error);
    }

}

